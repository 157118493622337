import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { ASSET_IMAGES } from "app/utils/constants/paths";

import { Alert, AlertTitle, Stack } from "@mui/material";
import MarketingCampaign from "app/shared/widgets/MarketingCampaign/MarketingCampaign";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Referralusers from "app/shared/widgets/ReferralUsers/ReferralUsers";
import PaymentsIcon from "@mui/icons-material/Payments";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  referralusers,
  lasttransactions,
  postRequest,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

import Div from "@jumbo/shared/Div/Div";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ContentCopyOutlined } from "@mui/icons-material";
// import { ShoppingBagOutlined, ShoppingBasket, ShoppingCartCheckoutRounded } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import UpgradePlan from "../../../shared/widgets/UpgradePlan";
import UserSummary from "app/shared/widgets/UserSummary";

const Crypto = () => {
  const [lasttransactionsdata, setLastTransactionsData] = useState([]);
  const [picturelink, setPictureLink] = useState([]);
  const [referralusersdata, setReferralUsersData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [referralLink, setReferralLink] = useState("");

  const [dashboardData, setDashboardData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { loginUserData, loading, setalertData } = useContext(CustomProvider);
  let userData = loginUserData;
  const promoStatus = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "promo"
  );
  const promoImage = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "promo_image"
  );

  const Swal = useSwalWrapper();
  const sweetAlerts = () => {
    Swal.fire({
      imageUrl: `https://onedollarnetwork.threearrowstech.com/uploads/promo/${promoImage.keyvalue}`, // Replace with your image URL
      imageAlt: "Promo image",
      confirmButtonText: "Close",
    });
  };

  const Salary = () => {
    postRequest(
      "/cronjobsalary",
      "",
      (response) => {
          console.log("salary get Successfully");
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  const AllDashboardData = () => {
    postRequest(
      "/dashboarddata",
      "",
      (response) => {
        console.log('response', response)
        setDashboardData(response?.data?.data);
        console.log("response?.data?.data", response)
        if (response?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const ReferralUsers = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      referralusers(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const TransactionData = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      lasttransactions(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const fetchData = () => {
    setLoader(true);

    // Make all API calls concurrently using Promise.all
    Promise.all([ReferralUsers(), TransactionData()])
      .then(([referralUsersResponse, transactionsResponse]) => {
        setReferralUsersData(referralUsersResponse?.data?.data?.entries);
        setPictureLink(referralUsersResponse?.data?.data?.picturelink);
        setLastTransactionsData(transactionsResponse?.data?.data?.entries);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error?.response?.data);
      });
  };

  useEffect(() => {
    Salary();
    AllDashboardData();
    fetchData();
    if (promoStatus?.keyvalue === "On") {
      sweetAlerts();
    }
  }, []);

  if (loading || loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }
  const handleCopy = () => {
    setCopied(true);
    // Update referralLink before copying
    setReferralLink(loginUserData?.referrallink);
    navigator.clipboard.writeText(loginUserData?.referrallink);
    setalertData({
      show: true,
      message: "Referral link copied to clipboard",
      variant: "success",
    });
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}></Grid>
      <Grid item xs={12} sm={4}>
        {dashboardData?.salary !== 'Starter' && (
          <Alert variant="filled" severity="success">
            <AlertTitle>Congratulations! You got — <strong>{dashboardData?.salary} Salary!</strong></AlertTitle>
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            {/* First 6 columns */}
            <Grid item xs={12} md={12}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <UserSummary userData={userData} picturelink={picturelink} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={`$${userData?.current_balance}`}
                      title="Current Balance"
                      color="primary.main"
                      icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={`$${dashboardData?.totalRoiBonus}`}
                      title="ROI"
                      color="info.main"
                      icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={dashboardData?.totalTeamSale}
                      title="Total Team Sale"
                      color="info.main"
                      icon={<Diversity3Icon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={dashboardData?.totalProfit + ' (' + dashboardData?.incomePercentage + '%)'}
                      title="Total Profit"
                      color="info.main"
                      icon={<TrendingUpIcon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={dashboardData?.investment}
                      title="Investment"
                      color="info.main"
                      icon={<ReceiptIcon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>

                 

          </Grid>
       
              </Grid>
             
            </Grid>
            
            {/* Next 6 columns */}
           
            <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={dashboardData?.totalActiveReferral}
                      title="Direct Active Referrals"
                      color="info.main"
                      icon={<GroupAddIcon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <ObjectCountRevenue
                      value={`$${dashboardData?.totalUnilevelBonus}`}
                      title="Unilevel Bonus"
                      color="info.main"
                      icon={<PaymentsIcon fontSize="large" />}
                      vertical={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
            <Grid item xs={12}>
        <ObjectCountRevenue
          value={`$${dashboardData?.totalPayoutAmount}`}
          title="Total Payout"
          color="info.main"
          icon={<PaymentsIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>

      </Grid>

                </Grid>
            </Grid>



          </Grid>
        </Grid>
      </Grid>







      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={12} md={8}>
            <JumboCardQuick
              title={"Referral Link"}
              subheader={""}
              wrapperSx={{ p: 1 }}
              headerSx={{ borderBottom: 1, borderBottomColor: "divider" }}
            >
              {userData?.status === "approved" ? (
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      fullWidth

                      type="text"
                      value={loginUserData?.referrallink}
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <IconButton>
                            {copied ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <ContentCopyOutlined
                                onClick={handleCopy}
                                color="warning"
                              />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Alert
                  icon={<WarningAmberIcon />}
                  variant="outlined"
                  style={{ color: "red" }}
                >
                  Please buy a membership to activate your referral link.
                </Alert>
              )}
            </JumboCardQuick>
          </Grid>
          

        </Grid>
      </Grid>


      {/* <Grid item xs={12} lg={6}>
        <PortfolioBalance
          totalroi={userData?.totalEarningdbData?.total_earning}
          weeklyroi={userData?.totalEarningdbData?.weekly_earning}
          monthlyroi={userData?.totalEarningdbData?.monthly_earning}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <EarningExpenses
          earning={userData?.totalEarning}
          spending={userData?.totalSpending}
        />
      </Grid> */}
      <Grid item xs={12} lg={6}>
        <Referralusers
          picturelink={picturelink}
          referralusersdata={referralusersdata}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
  <MarketingCampaign lasttransactionsdata={lasttransactionsdata} />
</Grid>
<Grid item xs={12}>

  <Grid container spacing={2}>
  <Grid item xs={12}>
      <Typography variant="h3" sx={{color:"#214285", fontWeight: 999, marginTop:'1%'}} align="center">SALARY QUALIFICATIONS</Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <img src={`${ASSET_IMAGES}/salary1.png`} style={{ width: '100%'}} alt="Salary 1" />
    </Grid>
    <Grid item xs={12} md={4}>
      <img src={`${ASSET_IMAGES}/salary2.png`} style={{ width: '100%'}} alt="Salary 2" />
    </Grid>
    <Grid item xs={12} md={4}>
      <img src={`${ASSET_IMAGES}/salary3.png`} style={{ width: '100%'}} alt="Salary 3" />
    </Grid>
    <Grid item xs={12} md={4}>
      <img src={`${ASSET_IMAGES}/salary4.png`} style={{ width: '100%'}} alt="Salary 4" />
    </Grid>
    <Grid item xs={12} md={4}>
      <img src={`${ASSET_IMAGES}/salary5.png`} style={{ width: '100%'}} alt="Salary 5" />
    </Grid>
    <Grid item xs={12} md={4}>
      <img src={`${ASSET_IMAGES}/salary6.png`} style={{ width: '100%'}} alt="Salary 6" />
    </Grid>
  </Grid>
</Grid>

      {/* <Grid item xs={12} sm={8}>
        <NewConnections />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RecentActivities userlevel={dashBoardUse?.levelopen} />
      </Grid> */}
    </Grid>
  );
};

export default Crypto;
