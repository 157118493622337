import React from "react";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ApiIcon from '@mui/icons-material/Api';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Import WhatsApp icon
const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{ fontSize: 20 }} />
            },
            {
                label: 'Packages',
                type: "collapsible",
                icon: <ApiIcon sx={{ fontSize: 20 }} />,
                children: [

                    {
                        uri: "/packages",
                        label: "Buy",
                        type: "nav-item"
                    },
                    {
                        uri: "/package-history",
                        label: "History",
                        type: "nav-item"
                    },

                ]
            },
            {
                uri: "/referrals",
                label: 'sidebar.menuItem.referrals',
                type: "nav-item",
                icon: <GroupsIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/payout",
                label: 'sidebar.menuItem.rpayout',
                type: "nav-item",
                icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />
            },
            {
                label: 'sidebar.menuItem.reports',
                type: "collapsible",
                icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/payout-summary",
                        label: "Payout Summary",
                        type: "nav-item"
                    },
                    {
                        uri: "/unilevel-summary",
                        label: "sidebar.menuItem.unilevelsummary",
                        type: "nav-item"
                    },
                    {
                        uri: "/roi-summary",
                        label: "Roi Summary",
                        type: "nav-item"
                    },
                ]
            },
            {
                uri: "/profile",
                label: 'sidebar.menuItem.profile',
                type: "nav-item",
                icon: <AssignmentIndIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/news",
                label: 'sidebar.menuItem.news',
                type: "nav-item",
                icon: <NewspaperIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "https://wa.me/+447376151944", // WhatsApp link
                label: 'Support', // Support label
                type: "nav-item",
                icon: <WhatsAppIcon sx={{ fontSize: 20 }} />, // WhatsApp icon
                onClick: () => window.open("https://wa.me/+447376151944", "_blank") // Open link in new window
            },
            {
                uri: "/logout",
                label: 'sidebar.menuItem.logout',
                type: "nav-item",
                icon: <LogoutIcon sx={{ fontSize: 20 }} />
            },
        ]
    },

];

export default menus;
